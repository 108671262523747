body {
    /*background-color: #f0f4f8;*/ /* Light background */
    font-family: 'Arial', sans-serif;
    margin: 0;
  }
  
  .profile-container {
    background-color: #ffffff; /* White background for the profile */
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    padding: 40px;
    width: 350px; /* Fixed width */
    margin: auto; /* Center align */
    text-align: center; /* Center text */
  }
  
  h1 {
    margin-bottom: 20px;
    color: #333; /* Darker color for the heading */
  }
  
  .masked-email {
    color: #555; /* Slightly lighter for the masked email */
    margin-bottom: 20px;
  }
  
  .change-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc; /* Border color */
    border-radius: 4px; /* Rounded corners */
    transition: border-color 0.3s; /* Smooth transition */
  }
  
  .input-field:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
  
  .change-password-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff; /* Bootstrap primary color */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .change-password-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
  .profile-message {
    text-align: center; /* Center the message */
    color: #333; /* Dark color for the message */
  }
  .logout-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #d32f2f;
  }
  
  